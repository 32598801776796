.profileTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.profTopLeft {
  display: flex;
  align-items: center;
  gap: 30px;
}

.profTopLeft > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.profTopLeft > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.profTopRight {
  display: flex;
  align-items: center;
  gap: 90px;
}

.profTopFunds {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profTopFundIcon {
  font-weight: 600;
  font-size: 50px;
  line-height: 68px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profTopFunds > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #abadb3;
}

.profileCards {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profRightCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profLeftCard {
  background: #202531;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 850px;
}

.profLeftCard > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.profLeftCard > h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.profOpenIn {
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 20px 0 40px 0;
}

.eachOpenIn {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  padding: 10px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.eachOpenIn > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
}

.profLeftCard > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.manual {
  margin-bottom: 30px;
}

.profInputs {
  width: 100%;
  background: #292e3a;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.profInputs > input {
  background: none;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 15px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.profInputs > button {
  background: linear-gradient(180deg, #6678fa 0%, #475ce6 100%);
  border-radius: 10px;
  color: #ffffff;
  padding: 12px 30px;
  border: none;
  cursor: pointer;
}

.profInputs > button:hover {
  background: linear-gradient(180deg, #95a2ff 0%, #677bff 100%);
}

.profLeftCard > h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  margin-top: 30px;
}

.profRightTopCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #202531;
  border-radius: 20px;
  padding: 30px;
  height: 500px;
}

.buyTon > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 15px;
}

.buyTon > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.buyTon > p {
  display: none;
}

.buyTonLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.eachTonLink {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eachTonLink > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.profRightBottomCard {
  background: #202531;
  border-radius: 20px;
  padding: 30px;
  height: 335px;
}

.showName {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showName > h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.showOrHide {
  display: flex;
  align-items: center;
  gap: 5px;
}

.eachShow {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.eachShow > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.bottomLink {
  margin: 30px 0;
}

.profRightBottomCard > h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.profRightBottomCard > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

@media screen and (max-width: 1024px) {
  .profileTop {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }
  .profTopFundIcon > span {
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.02em;
  }
  .profTopFunds > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
  }
  .profOpenIn {
    flex-direction: column;
  }
  .profileCards {
    flex-direction: column;
  }
  .profLeftCard {
    width: 100%;
    padding: 20px;
  }
  .profRightCards {
    width: 100%;
  }
  .profQR {
    justify-content: center;
  }
  .buyTon > span {
    display: none;
  }
  .buyTon > p {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #868686;
  }
  .profRightBottomCard {
    padding: 20px;
    height: 320px;
  }
  .profRightTopCard {
    padding: 20px;
  }
  .profTopRight {
    gap: 40px;
  }
  .profQR {
    flex-direction: row;
  }
}
