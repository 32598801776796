.navigation {
  width: 100%;
  background: linear-gradient(180deg, #252b38 0%, #20232f 100%);
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  position: relative;
}

.navLogo {
  cursor: pointer;
}

.navbar {
  width: 100%;
  max-width: calc(100% - 230px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLeftPart {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navLeftPart > span {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
}

.navLeftPart > span:hover {
  color: #27beff;
}

.navRightPart {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: 1px solid #4d5168 !important;
}

.navFunds {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 15px;
}

.eachFund {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}

.navFunds > button {
  background: linear-gradient(180deg, #6678fa 0%, #475ce6 100%);
  border-radius: 10px;
  padding: 10px 15px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.navFunds > button:hover {
  background: linear-gradient(180deg, #95a2ff 0%, #677bff 100%);
}

.navDropdown {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.navDropdown > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.mobileNavRightPart {
  display: none;
}

.loginViaTelegram {
  background: linear-gradient(180deg, #46c1ff 0%, #127db0 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;
  cursor: pointer;
}

.loginViaTelegram > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  background: linear-gradient(180deg, #252b38 0%, #20232f 100%) !important;
}

.navLeftPart > span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.navLeftPart > span > img {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 1024px) {
  .navLeftPart {
    display: none;
  }
  .navRightPart {
    display: none;
  }
  .mobileNavRightPart {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .navbar {
    justify-content: flex-end;
    max-width: calc(100% - 150px);
  }
  .navMenu {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
  .navMenu > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  .loginViaTelegram {
    padding: 10px;
  }
}
