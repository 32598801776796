.betOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.betOptions > span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.betBg {
  background: #23272d;
  border-radius: 20px;
  padding: 40px;
}

.myBet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.myBet > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.winChance {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
}

.winPercent {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  border: 2px solid #ff1843;
  border-radius: 10px;
  padding: 3px;
}

.upMyBet {
  margin-top: 35px;
}

.myBetInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 230px;
}

.myBetInput > input {
  padding: 15px;
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  color: #ffffff;
  width: 100%;
}

.myBetInput::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #868686;
}

.myBetInput > input:focus {
  background: #232732;
  border: 0.5px solid #7694cd;
}

.cancelBet {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.upBet {
  background: #38404f;
  border-radius: 10px;
  cursor: pointer;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBet {
  display: flex;
  gap: 7px;
}

.upBet > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.upBet:hover {
  background: #53627e;
  border-radius: 10px;
}

.submitMyBet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.stakeMyBet {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.stakeMyBet > span > img {
  width: 20px;
  height: 20px;
}

.myBetBalance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.myBetBalance > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #868686;
}

.myBetBalance > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #27beff;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1190px) {
  .myBetInput {
    width: 210px;
  }

  .stakeMyBet {
    width: 210px;
  }

  .upBet {
    width: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .betOptions > span {
    display: none;
  }

  .betOptions {
    width: 100%;
  }

  .betPlayers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 25px 0;
  }

  .eachBetPlayer {
    border: 0.5px solid #ff1944;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 5px 5px 0;
    height: 20px;
    position: relative;
  }

  .eachBetPlayer > img {
    width: 25px;
    height: 25px;
    border: 1px solid #ff1944;
    border-radius: 100px;
  }

  .eachPlayerChance {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2px;
  }

  .eachPlayerChance > img {
    width: 12px;
    height: 12px;
  }

  .betBg {
    padding: 20px;
  }

  .submitMyBet {
    flex-direction: column;
  }

  .stakeMyBet {
    width: 100%;
    margin-top: 15px;
  }

  .myBetBalance {
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
  }

  .myBetInput {
    width: 180px;
  }

  .winChance {
    justify-content: center;
  }

  .inputBet {
    justify-content: center;
  }
}
