.myWinningTickts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.eachWinningTicket {
  background: #202531;
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.eachWinBottom {
  border-top: 0.5px solid #3c4458;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 100px;
  padding-top: 35px;
  margin-top: 25px;
  position: relative;
}

.winnerWin {
  position: relative;
  left: 35px;
}

.winnerWinAvatar {
  width: 50px;
  height: 50px;
}

.winnerCrown {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -20px;
  left: 12px;
}

.eachWinDetails {
  display: flex;
  gap: 50px;
}

.eachWinDetail1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.eachDet {
  display: flex;
}

.eachDet > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #868686;
  width: 95px;
}

.eachDet > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.eachWin {
  padding: 0 !important;
}

.shareMyWin {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0;
}

.shareMyWin > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.mobileShareMyWin {
  display: none;
}

@media screen and (max-width: 1095px) {
  .eachWinBottom {
    gap: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .eachWinBottom {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 0;
    margin: 0;
  }
  .eachWinDetails {
    gap: 10px;
    flex-direction: column;
  }
  .shareMyWin {
    display: none;
  }
  .mobileShareMyWin {
    display: flex;
    position: relative;
    width: 125px;
    margin: 20px auto 0;
  }
  .winnerWin {
    left: 0;
  }
}
