.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layoutSide {
  display: flex;
  min-height: 100vh;
}

.outlet {
  width: 100%;
  background: #1B1E23;
  
}
