.active {
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 82%);
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  z-index: 5;
}

.inactive {
  display: none;
}

.loginPopup {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 8px 18px rgba(24, 39, 75, 0.12);
  padding: 40px 50px;
  background: #202531;
  border-radius: 20px;
  display: grid;
  align-items: center;
  width: 450px;
  overflow-y: auto;
  position: relative;
}

.closeLoginPopup {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #38404f;
  border: 0.5px solid #353b4a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  cursor: pointer;
}

.loginPopup > h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
}

.mainLogPop {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin: 30px 0;
}

.mainLogPop > img {
  width: 170px;
  height: 170px;
}

.mainLogPopRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainLogPopRight > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.logPopIcons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logPopIcons > img {
  width: 36px;
  height: 36px;
}

.downloadTg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadTg > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.goToTg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .loginPopup {
    padding: 40px 20px;
    justify-content: center;
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .mainLogPop {
    flex-direction: column;
  }
  .mainLogPop > img {
    width: 130px;
    height: 130px;
  }
}
