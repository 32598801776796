.crossedFingers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0;
}

.crossedFingers>img {
    width: 20px;
    height: 20px;
}

.crossedImg {
    width: 34px !important;
    height: 34px !important;
}

@media screen and (max-width:1024px){
    .crossedFingers {
        margin: 40px 0 0 0;
    }
}