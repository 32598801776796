.footer {
  width: 100%;
  background: #16181c;
  padding: 60px 0 30px;
}

.mainFooter {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  width: 90%;
  color: #868686;
  gap: 50px;
}

.leftFooter {
  max-width: 400px;
}

.leftFooter > svg {
  margin-bottom: 20px;
}

.leftFooter > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.whiteFooter {
  color: #ffffff;
}

.whiteFooter:hover {
  color: #27beff;
  cursor: pointer;
}

.leftFooter > h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 60px;
}

.footerEmail {
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  width: 100%;
}

.footerEmail {
  margin: 20px 0 40px !important;
}

.basedOn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.basedOn > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

.rightFooter {
  display: flex;
  gap: 40px;
}

.eachColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.eachColumn > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 5px;
}

.eachColumn > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
  cursor: pointer;
}

.eachColumn > span:hover {
  color: #27beff;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 960px;
  width: 90%;
}

.footerBottom > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

@media screen and (max-width: 1024px) {
  .mainFooter {
    margin: 0 20px;
    flex-direction: column;
  }
  .leftFooter {
    max-width: 100%;
  }
  .mainFooter {
    gap: 0;
  }
  .footerBottom {
    margin: 50px 20px 0px 20px;
    flex-direction: column;
    gap: 15px;
  }
  .rightFooter {
    justify-content: space-between;
  }
}
