.rouletteTickets {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  overflow-x: auto;
}

.eachTicket {
  background: #202531;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 80px;
  gap: 20px;
  width: 100%;
}

.eachTicket > img {
  width: 100px;
  height: 100px;
}

.eachTicket > span {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

@media screen and (max-width: 1024px) {
  .eachActiveRoulette {
    flex-direction: column;
  }
  .activeTickets {
    flex-direction: column;
  }
  .activeRight {
    align-items: center;
  }
}
