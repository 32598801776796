.homeRoulette {
  background: #23272d;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.homeRouletteLeftPart {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.homeRouletteLeftTopPart {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.homeRouletteLeftTopPart > h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.homeRouletteLeftTopPart > h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.homeRouletteRightPart {
  background: linear-gradient(180deg, #35ff14 0%, #04860a 100%);
  border: none;
  border-radius: 10px;
  padding: 10px 60px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
}

.homeRouletteRightPart:hover {
  background: linear-gradient(180deg, #24ff00 0%, #0ab011 100%);
}

.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart > img {
  width: 250px;
  height: 250px;
}

.chartBtn {
  display: none;
}

@media screen and (max-width: 1024px) {
  .homeRoulette {
    padding: 25px 20px;
    flex-direction: column;
    margin-top: 40px;
  }
  .homeRouletteLeftBottomPart {
    display: none;
  }
  .chart {
    width: 100%;
  }
  .chart > img {
    margin: 40px 0;
  }
  .chartBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .chartBtn > img {
    width: 30px;
    height: 30px;
  }
}
