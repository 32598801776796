.backToRaffleList {
  margin-bottom: 30px;
}

.backToRaffleList > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #868686;
  cursor: pointer;
}

.singleRaffHeadingImg {
  display: flex;
  align-items: center;
  gap: 50px;
}

.singleRaffHeadingImg > img {
  width: 300px;
  height: 300px;
}

.singleRaffHeading {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.singleRaffHeading > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.singleRaffHeading > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.singleCryptos {
  display: flex;
  align-items: center;
  gap: 40px;
}

.singleRaffHeadingImg > h1 {
  display: none;
}

.progress_bar {
  appearance: none;
  width: 100%;
  height: 30px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress_bar::-webkit-slider-thumb {
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background: #a80b98; */
}

.progress_bar::-webkit-slider-runnable-track {
  appearance: none;
  width: 0;
  height: 0;
}

.progress_parent {
  position: relative;
  width: 600px;
  height: 40px;
  margin-top: 20px;
}

.progressValue {
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 1;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.takeYourChance {
  background: #23272d;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 30px 0 50px 0;
  gap: 10px;
}

.takeYourChance > h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.takeYourChance > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
}

.buyThisTicket {
  border-radius: 10px;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  padding: 10px 70px;
  margin-top: 40px;
}

.raffleName {
  display: flex;
  gap: 170px;
}

.raffleNameLeft {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.raffleNameLeft > h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.raffleNameLeft > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.raffleNameLeft > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #1f9ffd;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.raffleNameRight {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 60px;
}

.raffleNameRightTop {
  display: flex;
  align-items: center;
  gap: 50px;
}

.raffleNameRight > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

.eachRaffleName {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.eachRaffleNameIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.eachRaffleName > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.eachRaffleNameIcon > p {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.singleBanners {
  gap: 20px;
  width: 100%;
  margin-top: 70px;
  display: flex;
}

.eachSingleBanner {
  background: #202531;
  border-radius: 20px;
  width: 100%;
  height: 220px;
}

@media screen and (max-width: 1024px) {
  .backToRaffleList {
    padding: 0 20px;
  }
  .buyThisTicket {
    margin: 0;
  }
  .singleRaffHeadingImg > h1 {
    display: flex;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }
  .singleRaffHeading > h1 {
    display: none;
  }
  .singleRaffHeadingImg {
    flex-direction: column;
  }
  .singleRaffHeading {
    align-items: center;
    padding: 0 20px;
  }
  .progress_parent {
    width: 100%;
  }
  .takeYourChance {
    padding: 20px;
  }
  .buyThisTicket {
    width: 100%;
    padding: 10px;
  }
  .singleBanners {
    flex-direction: column;
  }
  .raffleName {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .raffleNameLeft {
    width: 100%;
    padding: 0 20px;
  }
  .raffleNameRight {
    width: 100%;
    padding: 0 20px;
    margin: 0;
  }
}
