.activeRoulettes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 50px 0 110px 0;
}

.eachActiveRoulette {
  background: #202531;
  border-radius: 20px;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  position: relative;
}

.eachActiveRoulette > img {
  width: 230px;
  height: 230px;
  position: relative;
}

.activeRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}

.activeCryptos {
  display: flex;
  align-items: center;
  gap: 70px;
}

.eachActiveCrypto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CryptoIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}

.activeTickets {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.activeTickets > button {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
}

.activeTickets > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #02f0ff;
  cursor: pointer;
}

.activeRight > h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
}

.activeRight > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.eachActiveCrypto > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #868686;
}

.cryptoIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cryptoIcon > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

@media screen and (max-width: 1024px) {
  .eachActiveRoulette > img {
    top: -60px;
    width: 200px;
    height: 200px;
  }
  .activeRoulettes {
    margin: 100px 0 50px 0;
    gap: 50px;
  }
  .eachActiveRoulette {
    gap: 0;
    padding: 30px 20px 0;
  }
  .activeRight {
    position: relative;
    top: -40px;
    width: 100%;
  }
  .activeCryptos {
    gap: 30px;
    justify-content: space-between;
  }
  .activeTickets > button {
    width: 100%;
  }
}