.hamburger {
  position: absolute;
  background: #16181c;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px;
  top: 60px;
  left: 0;
  min-height: calc(100vh - 60px);
}

.dynamicHamButton {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.dynamicHamButton > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.hamburger > h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}

.hamGames {
  display: grid;
  grid-template-columns: auto auto;
  gap: 7px;
  margin-bottom: 25px;
}

.eachHamGame {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamAd {
  width: 100%;
  padding: 20px 0;
}

.shareYourLuck {
  background: linear-gradient(180deg, #46c1ff 0%, #127db0 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  gap: 10px;
  margin-bottom: 30px;
}

.shareYourLuck > span {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
