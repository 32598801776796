.rouletteOptions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.betDiagram > img {
  width: 300px;
  height: 300px;
}

.sandHour {
  width: 22px;
  height: 22px;
}

.mobileTime {
  display: none;
}

.betPlayers {
  display: none;
}

.betPlayersWeb {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.eachBetPlayerWeb {
  background: #23272d;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 15px;
}

.eachBetPlayerWeb > img {
  width: 40px;
  height: 40px;
  border: 1px solid #ff1944;
  border-radius: 100px;
}

.eachPlayerChanceWeb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
}

.eachPlayerChanceWeb > img {
  width: 12px;
  height: 12px;
}

.webPrice {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 1024px) {
  .betPlayersWeb {
    display: none;
  }

  .rouletteBets > h1 {
    display: none;
  }

  .rouletteOptions {
    align-items: center;
    flex-direction: column;
  }

  .mobileTime {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 30px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
}
