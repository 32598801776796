.buyQr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px;
}

.buyQr > img {
  width: 170px;
  height: 170px;
}

.buyQrPopup > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
}

.profOpenInQr {
  margin: 20px 0 !important;
  justify-content: center;
}
