.stakeMyBetBee {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: transparent;
}

.stakeMyBetBee > span > img {
  width: 20px;
  height: 20px;
}

.totalnDetailsP {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #abadb3;
}
