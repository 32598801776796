.sendToWallet {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sendToWallet > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.sendToWallet > input {
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px;
}

.transferAmount {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.transferAmount > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.transferInput {
  width: 190px;
  height: 45px;
  position: relative;
}

.transferInput > input {
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  outline: none;
  padding: 15px;
}

.cancelTransfer {
  position: absolute;
  top: 13px;
  right: 10px;
}

.transferAmountRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.transferAmountRight > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
}

.transferLink {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #1f9ffd;
  cursor: pointer;
}

.transferButton {
  height: 48px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
}

.transferPopup > span {
  margin: 15px 0 25px;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

@media screen and (max-width: 1024px) {
  .transferInput {
    width: 140px;
  }
}
