.howToPlay {
    display: flex;
    flex-direction: column;
    margin: 15px 0 40px;
    gap: 15px;
}

.howToPlay>h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
}

.howToPlay>ol {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #868686;
    padding-left: 20px;
}

@media screen and (max-width: 1024px) {
    .howToPlay {
        padding: 0 20px;
    }
}