.myWonBet {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 20px;
}

.betWinnerRel {
  position: relative;
  margin-top: 20px;
}

.betWinnerRel > img {
  width: 70px;
  height: 70px;
}

.betWinnerRelCrown {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  top: -40px;
  left: 10px;
}

.myWinDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.myWinDetailsP {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #04ff00;
}

.myWinDetailsSpan {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.myWinDetailsSpan > svg {
  width: 23px;
  height: 23px;
}

.myWinDetailsGreySpan {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #abadb3;
}
