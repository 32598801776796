.previousGames {
  margin-top: 50px;
}

.previousGames > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.allPrevGames {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 30px 0 20px 0;
}

.eachPrevGame {
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  gap: 20px;
}

.prevGameLeft {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.prevGameLeft > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.iWin {
  color: #04ff00;
}

.totalWin {
  color: #abadb3;
}

.prevGameRight > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #abadb3;
}

.prevGameCenter {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 570px;
  flex-wrap: wrap;
}

.prevGameCenter > span {
  cursor: pointer;
}

.prevGameWinner {
  width: 22px !important;
  height: 22px !important;
  position: absolute;
  top: -12px;
  left: 10px;
  border: none !important;
}

.eachPrevGameWinner {
  position: relative;
}

.pastGames {
  display: none;
}

.mobPrevGame {
  display: flex;
  gap: 50px;
}

.seeMorePreviousGames {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  width: 180px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .previousGames > h1 {
    display: none;
  }

  .pastGames {
    display: flex !important;
    margin: 40px 0 0 20px;
  }

  .eachPrevGame {
    flex-direction: column;
    padding: 20px;
  }
}
