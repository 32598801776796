.rouletteFilter {
  background: #23272d;
  border-radius: 10px;
  width: 420px;
  margin-top: 30px;
  display: flex;
}

.eachRouletteFilter {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  cursor: pointer;
}

.eachRouletteFilter:hover {
  background: #292e3a;
}

.eachRouletteFilter > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
}

@media screen and (max-width: 1024px) {
  .rouletteFilter {
    width: 95%;
    margin: 30px 10px 0 10px;
  }
  .rouletteTickets {
    width: 95%;
    margin: 0 10px 50px 10px;
  }
}
