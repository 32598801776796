.accordion {
  border-top: 1px solid #3d4553;
  width: 100%;
}

.eachAccordionItem {
  padding: 15px 10px;
  border-bottom: 1px solid #3d4553;
  gap: 15px;
}

.eachAccordionTitle {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eachAccordionTitle > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.eachAccordionItem {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: .5s;
  height: auto;
}

.eachAccordionItem > p {
  height: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
  transition: height .5s;
}
