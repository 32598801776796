.sidebar {
  background: linear-gradient(174.42deg, #242732 0%, #181a25 100%);
  max-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.sidebar > h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-left: 20px;
  margin: 30px 0 10px 0;
}

.eachMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
}

.eachMenu:hover {
  background: #272b38;
}

.eachMenuItems {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 10px;
  height: 40px;
  cursor: pointer;
}
.menuItem:hover {
  background: #272b38;
}

.menuItem > img {
  width: 12px;
  height: 12px;
}

.menuItem > h2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.eachMenuItems > img {
  width: 12px;
  height: 12px;
}

.eachMenuItems > h2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.eachMenu > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.sideAds {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
}

.sideAd1 {
  background: linear-gradient(180deg, #46c1ff 0%, #127db0 100%);
  border-radius: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  gap: 10px;
  margin: 0 auto;
  cursor: pointer;
}

.sideAdEmoji {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sideAd1 > span {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.sideAdEmoji > img {
  width: 20px;
  height: 20px;
}

.sideAd2 {
  background: #9746ff;
  border-radius: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  gap: 10px;
  margin: 0 auto;
  cursor: pointer;
}

.sideAd2 > span {
  font-weight: 800;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 3px;
  color: #6c0ee2;
}

.sideAdEmoji2 > img {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
