.promos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #202531;
    border-radius: 20px;
    padding-left: 45px;
    height: 100px;
}

.promos>span {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    opacity: 0.3;
}

.promos>img {
    width: 288px;
    height: 121px;
}

@media screen and (max-width: 1024px) {
    .promos {
        padding-left: 15px;
        height: 80px;
    }

    .promos>img {
        width: 170px;
        height: 65px;
    }

    .promos>span {
        font-size: 14px;
        line-height: 19px;
    }
}