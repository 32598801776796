.gamesArchive > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 30px;
}

.gamesArchive > select {
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  color: #ffffff;
  width: 420px;
  height: 44px;
  padding: 0 15px;
  outline: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0 30px;
}

.tableDiv {
  overflow: auto;
}

.tableHead {
  background: #23272d;
  border-radius: 10px;
}

.tableMarginTh {
  width: 20px;
}

th {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

.tabelTh {
  height: 44px;
  min-width: 120px;
}

td {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.tableMargin {
  visibility: hidden;
}

.tableMargin > {
  height: 10px !important;
}

th:first-of-type {
  border-radius: 10px 0 0 10px;
}

th:last-of-type {
  border-radius: 0 10px 10px 0;
}

td:last-of-type {
  border-radius: 0 10px 10px 0;
}

td:first-of-type {
  border-radius: 10px 0 0 10px;
}

td > img {
  width: 22px;
  height: 22px;
}

.tableTr {
  height: 36px;
}

.tabelMarginTh {
  height: 15px;
}

tr:hover {
  background: #23272d;
}

@media screen and (max-width: 1024px) {
  .gamesArchive > select {
    width: 100%;
  }
  .gamesArchive > h1 {
    margin-bottom: 20px;
  }
  .backFromArchive {
    padding: 0;
  }
  .backToRaffleList {
    margin-bottom: 10px;
  }
  .pagination > li > a,
  .pagination > li > span {
    padding: 8px;
  }
}