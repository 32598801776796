body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Manrope", sans-serif;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Manrope;
  src: url("../src//fonts/Manrope-VariableFont_wght.ttf");
  font-weight: 500;
}

.greenButton {
  background: linear-gradient(180deg, #35ff14 0%, #04860a 100%);
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.greenButton:hover {
  background: linear-gradient(180deg, #24ff00 0%, #0ab011 100%);
}

.blueButton {
  background: linear-gradient(180deg, #46c1ff 0%, #127db0 100%);
  cursor: pointer;
}

.blueButton:hover {
  background: linear-gradient(180deg, #6fcfff 0%, #059feb 100%);
}

.purpleButton {
  background: linear-gradient(180deg, #6678fa 0%, #475ce6 100%);
  cursor: pointer;
}

.purpleButton:hover {
  background: linear-gradient(180deg, #95a2ff 0%, #677bff 100%);
}

.transparentButton {
  border: 0.5px solid #3d4553;
  color: #ffffff;
}

.transparentButton:hover {
  border: 0.5px solid #27beff;
  color: #27beff;
}

.greyButton {
  background: #38404f;
  cursor: pointer;
}

.greyButton:hover {
  background: #53627e;
}
