.loadingSpan {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
  text-align: center;
}

.loadingPopup {
  margin: 50px auto;
}
