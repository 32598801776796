.homeLotteries {
  display: flex;
  align-items: center;
  gap: 40px 0;
  flex-wrap: wrap;
  margin: 40px 0;
  justify-content: space-between;
}

.eachLottery {
  width: 48%;
  background: #23272d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.eachLotteryTop {
  width: 100%;
  height: 230px;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(180deg, #1c293f 0%, rgba(46, 53, 65, 0.6) 100%);
}

.eachLotteryBottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px 30px 20px;
}
.eachLotteryBottom > h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.eachLotteryBottom > h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.eachLotteryBottom > h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0eff00;
  cursor: pointer;
}

.eachLotteryBottom > h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #868686;
}

@media screen and (max-width: 1024px) {
  .eachLottery {
    width: 100%;
  }
  .homeLotteries {
    gap: 20px;
    margin: 0;
  }
}
