.guides {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eachGuide {
  background: #23272d;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  width: 85%;
  cursor: pointer;
}

.guideLeftPart {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.guideLeftPart > h2 {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
}

.guideLeftPart > h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
  word-break: inherit;
}

.eachGuide > img {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1024px) {
  .guides {
    flex-direction: column;
  }
  .eachGuide {
    width: 100%;
    padding: 20px;
    gap: 20px;
  }
}
