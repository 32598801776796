.buyTicketDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
}

.eachBuyTicketDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.eachBuyTicketDetail > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #868686;
}

.lineBreak {
  width: 100%;
  height: 1px;
  background: #3c4458;
  margin: 15px 0;
}

.eachBuyTicketDetailSpan {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  margin-top: 5px;
}

.myTonCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 30px 0 15px 0;
}

.tonCountInput {
  background: #292e3a;
  border: 0.5px solid #3d4553;
  border-radius: 10px;
  display: flex;
  width: 240px;
}

.tonCountInput > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tonCountInputLeft {
  border-right: 0.5px solid #3d4553;
  width: 44px;
  cursor: pointer;
}

.tonCountInputLeft:hover {
  color: #27beff;
  background: #232732;
  border-radius: 10px 0 0 10px;
}

.tonCountInputCenter {
  width: 100%;
  padding: 3px 0;
}

.tonCountInputRight {
  border-left: 0.5px solid #3d4553;
  width: 44px;
  cursor: pointer;
}

.tonCountInputRight:hover {
  color: #27beff;
  background: #232732;
  border-radius: 0 10px 10px 0;
}

.tonCountInputCenter > p {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
}

.tonCountInputCenter > span {
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.2px;
  color: #868686;
}

.upToMax {
  border-radius: 10px;
  width: 100px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payWithWallet {
  height: 48px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
}

.payWithDeposit {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  color: #1f9ffd;
  margin-top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .buyTicketPopup {
    display: flex;
    flex-direction: column;
  }
  .myTonCount {
    width: 100%;
  }
  .myTonCount {
    flex-direction: column;
  }
  .upToMax {
    width: 100%;
  }
  .payWithWallet {
    width: 100%;
  }
  .tonCountInput {
    width: 100%;
  }
}
