.myTicketsTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.myTicketsTitle > h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.myTicketsTitle > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.upcomingimg {
  width: 130px;
  height: 130px;
}

.mobUp {
  display: flex;
  gap: 20px;
}

.pagination {
  background: #23272d;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  height: 40px;
}

.pagination > li {
  list-style: none;
}

.pagination > li > a,
.pagination > li > span {
  float: left;
  padding: 8px 20px;
  line-height: 1.5;
  margin-left: -1px;
  color: #868686;
}

.pagination > li.actives > a {
  color: #fff;
}

.pagination > li > a:hover {
  background-color: #868686;
  color: white;
  cursor: pointer;
  border-radius: 8px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}

@media screen and (max-width: 1024px) {
  .myTicketsTitle {
    margin: 30px auto 0 auto;
    width: 95%;
  }
  .rouletteFilter {
    width: 95%;
    margin: 30px auto 0;
  }
  .upcomingimg {
    width: 100px;
    height: 100px;
  }
  .eachUpcomingTicket {
    flex-direction: column;
    padding: 15px;
  }
}
