.eachPage {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  max-width: 960px;
  width: 90%;
}

.home > h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  margin: 40px 0;
}

.HomeGames {
  display: none;
}

@media screen and (max-width: 1024px) {
  .eachPage {
    width: 100%;
  }
  .home > h2 {
    display: none;
  }
  .HomeGames {
    display: block !important;
    margin: 50px 0 20px 20px !important;
  }
}
