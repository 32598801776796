.finishedRoulettes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.eachFinishedRoulette {
  background: #202531;
  border-radius: 20px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eachFinishedLeft {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 70%;
}

.eachFinishedLeft > img {
  width: 130px;
  height: 130px;
}

.finishedRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.finishedRight > h2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.finishedRight > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

.eachFinishedRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  top: 15px;
}

.eachFinishedWinner {
  position: relative;
}

.winnerAvatar {
  width: 70px;
  height: 70px;
  border: 1px solid #f4b50f;
  border-radius: 100px;
}

.crown {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -30px;
  left: 15px;
}

.shareWin {
  background: linear-gradient(180deg, #46c1ff 0%, #127db0 100%);
  border-radius: 10px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.shareWin > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.shareWin:hover {
  background: linear-gradient(180deg, #6fcfff 0%, #059feb 100%);
}

.finishedRoulettes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.finishedRoulettes > button {
  border-radius: 10px;
  padding: 15px 60px;
  width: 180px;
  background: none;
  cursor: pointer;
}

.mobileFinishedCryptos {
  display: none;
}

.mobileFinishedDetails {
  display: none;
}

@media screen and (max-width: 1024px) {
  .roulettes {
    width: 100%;
  }
  .roulettes > h1 {
    margin-left: 20px;
  }
  .eachFinishedRoulette {
    flex-direction: column;
  }
  .finishedCryptos {
    display: none !important;
  }
  .mobileFinishedCryptos {
    display: flex;
    width: 100%;
    gap: 15px;
  }
  .eachFinishedLeft {
    width: 100%;
    align-items: flex-start;
  }
  .eachFinishedLeft > img {
    width: 100px;
    height: 100px;
  }
  .eachFinishedRoulette {
    gap: 20px;
  }
  .eachFinishedRight {
    display: none;
  }
  .mobileFinishedDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid #3c4458;
    width: 100%;
    padding: 20px 0 0;
  }
  .finishedDetailsLeft {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .eachFinishedDetailsLeft {
    display: flex;
    gap: 20px;
  }
  .eachFinishedWinner {
    top: 15px;
  }
  .crown {
    width: 40px;
    height: 40px;
    top: -30px;
    left: 5px;
  }
  .eachFinishedDetailsLeft > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #868686;
  }
  .eachFinishedDetailsLeft > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .winnerAvatar {
    width: 50px;
    height: 50px;
  }
  .cryptoIcon > span {
    font-size: 20px;
    line-height: 27px;
  }
}
