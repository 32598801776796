.MyUpcomingTickts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.eachUpcomingTicket {
  background: #202531;
  border-radius: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 25px;
}

.eachUpcomingTicket > img {
  width: 130px;
  height: 130px;
}

.upcomingRight {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 60%;
}

.upcomingRight > h2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.upcomingRight > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #868686;
}

.upcomingCryptos {
  display: flex;
  align-items: center;
  gap: 50px;
}

.eachUpcomingCrypto {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.upcomingCrypto > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.eachUpcomingCrypto > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #868686;
}

.mobileUpcomingCryptos {
  display: none;
}

.mobileUpcoming {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .upcomingRight {
    width: 100%;
  }
  .upcomingCryptos {
    display: none;
    gap: 40px;
  }
  .mobileUpcomingCryptos {
    display: flex;
  }
}
